import {
  getAuth,
  getIdToken,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import stringToMd5 from "@/utils/stringToMd5";

const useAuth = () => {
  const getCurrentUser = () => {
    return getAuth().currentUser;
  };

  const getToken = async () => {
    return getIdToken(getCurrentUser());
  };

  const loginWithSSO = async (token: string) => {
    try {
      const userCredential = await signInWithCustomToken(getAuth(), token);

      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const login = async (username: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        getAuth(),
        username,
        password
      );

      return userCredential;
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        try {
          await retryLoginWithMd5(username, password);
        } catch (error) {
          throw error;
        }
      } else {
        throw error;
      }
    }
  };

  const translateAuthErrors = (code: string) => {
    let msg = "Erro ao realizar o login";
    switch (code) {
      case "auth/user-not-found":
        msg = "Email do usuário ou senha inválidos";
        break;
      case "auth/wrong-password":
        msg = "Email do usuário ou senha inválidos";
        break;
      case "auth/too-many-requests":
        msg = "Usuário bloqueado devido a muitas tentativas de login.";
        break;

      default:
        break;
    }

    return msg;
  };

  const logout = async () => {
    await getAuth().signOut();
    localStorage.clear();
  };

  const resetPassword = (email: string) => {
    try {
      return sendPasswordResetEmail(getAuth(), email);
    } catch (err) {
      throw err;
    }
  };

  // tenta logar com o email e senha legada, que é em md5
  async function retryLoginWithMd5(username, password) {
    const md5Password = stringToMd5(password);

    try {
      const credentials = await signInWithEmailAndPassword(
        getAuth(),
        username,
        md5Password
      );

      const user = credentials.user;

      // atualiza password do usuário para o correto no firebase auth
      await updatePassword(user, password);

      return credentials;
    } catch (err) {
      throw err;
    }
  }

  return reactive({
    getCurrentUser,
    getToken,
    login,
    logout,
    translateAuthErrors,
    resetPassword,
    loginWithSSO,
  });
};

export default useAuth();
