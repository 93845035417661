import { abilitiesPlugin } from "@casl/vue";
import { ability } from "@/store/ability.js";

// plugin do casl
export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  });

  return {
    provide: {
      ability,
    },
  };
});
