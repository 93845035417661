import { useToast } from "@/store/toast";
import type { Options } from "@/store/toast";

export default defineNuxtPlugin(() => {
  const { show, close, toast } = useToast();

  return {
    provide: {
      notify: (message: string, options?: Options) => {
        const customOptions = { ...options };
        return show(message, customOptions);
      },
      notifyClose: () => {
        if (toast.value != null && toast.value.doNotAllowCloseFromPlugin) {
          return;
        }
        close();
      },
    },
  };
});
