export const NAME_URLS = (id?, usuario?) => {
  return [
    {
      name: "lojas",
      acao: "Listagem",
      categoria: "Loja",
    },
    {
      name: "lojas-cadastrar",
      acao: "Cadastrar",
      categoria: "Loja",
    },
    {
      name: "lojas-editar-id",
      acao: "Editar",
      categoria: "Loja",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
      ],
    },
    {
      name: "lojas-editar-id-convidar-usuario",
      acao: "Convidar",
      categoria: "Loja",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
        {
          chave: "Convidar",
          valor: `${usuario}`,
        },
      ],
    },
    {
      name: "redes",
      acao: "Cadastrar",
      categoria: "Rede",
    },
    {
      name: "redes-cadastrar",
      acao: "Cadastrar",
      categoria: "Rede",
    },
    {
      name: "redes-visualizar-id",
      acao: "Visualizar",
      categoria: "Rede",
      informacoes: [
        {
          chave: "Visualizar",
          valor: `${id}`,
        },
      ],
    },
    {
      name: "redes-editar-id",
      acao: "Editar",
      categoria: "Rede",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
      ],
    },
    {
      name: "redes-editar-id-convidar-usuario",
      acao: "Convidar",
      categoria: "Rede",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
        {
          chave: "Convidar",
          valor: `${usuario}`,
        },
      ],
    },
    {
      name: "permissoes",
      acao: "Cadastrar",
      categoria: "Grupo de Acesso",
    },
    {
      name: "permissoes-cadastrar",
      acao: "Cadastrar",
      categoria: "Grupo de Acesso",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
      ],
    },
    {
      name: "permissoes-editar-id",
      acao: "Editar",
      categoria: "Grupo de Acesso",
      informacoes: [
        {
          chave: "Editar",
          valor: `${id}`,
        },
      ],
    },
  ];
};
