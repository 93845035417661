type logLevels = "debug" | "info" | "warn" | "error";

const outputConfig = {
  debug: {
    name: "Debug",
    color: "#A5BF53",
  },
  info: {
    name: "Info",
    color: "#0ea5e9",
  },
  warn: {
    name: "Warning",
    color: "#FFCD30",
  },
  error: {
    name: "Error",
    color: "#C9352B",
  },
};

function getMethodName() {
  let error = {};

  try {
    throw new Error("");
  } catch (e) {
    error = e;
  }

  // return if browser does not have .stack property
  if (error.stack === undefined) {
    return "";
  }

  let methodName = error.stack.split("\n")[3];

  if (/@/.test(methodName)) {
    methodName = error.stack.split("\n")[2];
    methodName = methodName.trim().split("@")[0];
  }

  if (/ /.test(methodName)) {
    methodName = methodName.trim().split(" ")[1];
  }

  if (methodName && methodName.includes(".")) {
    methodName = methodName.split(".")[1];
  }

  return methodName;
}

const logLevels = ["debug", "info", "warn", "error"];

export default defineNuxtPlugin(() => {
  const logger: any = {
    info: (args: any) => {
      //console.info(msg, {});
      console.log(
        "%c" + outputConfig["info"].name + "%c" + getMethodName(),
        "background: " +
          outputConfig["info"].color +
          ";padding: 2px 8px; border-radius: 6px 0 0 6px; color: #fff",
        "background: #E5E5E5; padding: 2px 8px; border-radius: 0 6px 6px 0;"
      );

      //   if (args.length === 1) {
      //     console.log(
      //       "%c>>",
      //       "color: " + outputConfig["info"].color + "; margin-left:5px;",
      //       args
      //     );
      //   } else {
      //     args.forEach((arg) => {
      //       console.log(
      //         "%c>>",
      //         "color: " + outputConfig["info"].color + "; margin-left:5px;",
      //         arg
      //       );
      //     });
      //   }
    },
  };

  return {
    provide: {
      log: logger,
    },
  };
});
