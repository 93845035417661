import { initializeApp } from "firebase/app";
import {
  onAuthStateChanged,
  getAuth,
  connectAuthEmulator,
  getIdToken,
} from "firebase/auth";

import { connectStorageEmulator, getStorage } from "firebase/storage";

import { getAnalytics } from "firebase/analytics";

import { updateAbility, loading } from "@/store/ability";

export default defineNuxtPlugin(async () => {
  // pega valores de config
  const { environment, useFirebaseEmulators } = useRuntimeConfig();

  // development
  let firebaseConfig: any = {
    apiKey: "AIzaSyBW3CY3Sez6un9mGIXXiuizACkVev6Co3g",
    authDomain: "febrafar-dev.firebaseapp.com",
    projectId: "febrafar-dev",
    storageBucket: "febrafar-dev.appspot.com",
    messagingSenderId: "539817666245",
    appId: "1:539817666245:web:016c2a091c1bf510c332ac",
  };

  if (["staging", "treinamento"].includes(environment)) {
    // staging
    firebaseConfig = {
      apiKey: "AIzaSyDxHTA_BSjq9mz-IkB89BpXQzpBMr4nbLQ",
      authDomain: "febrafar-stg.firebaseapp.com",
      projectId: "febrafar-stg",
      storageBucket: "febrafar-stg.appspot.com",
      messagingSenderId: "416427449521",
      appId: "1:416427449521:web:1e47847ba6196dfcbd5690",
      measurementId: "G-C4H4YYRHC3",
    };
  } else if (environment === "production") {
    // production
    firebaseConfig = {
      apiKey: "AIzaSyCeYxJiLW8PIYVX5bCWP1OeqFSZxPHR0fA",
      authDomain: "febrafar-bee18.firebaseapp.com",
      projectId: "febrafar",
      storageBucket: "febrafar.appspot.com",
      messagingSenderId: "1019161575223",
      appId: "1:1019161575223:web:58969f905cc56fbe82a4b2",
      measurementId: "G-BMESYZ46YH",
    };
  }

  // inicializa app
  const app = initializeApp(firebaseConfig);

  /**
   * getAuth já seta persistência browserLocalPersistence e
   * outras mais
   */
  const auth = getAuth(app);
  const storage = getStorage(app);
  const analytics = getAnalytics(app);

  // emuladores
  if (useFirebaseEmulators) {
    try {
      connectAuthEmulator(auth, "http://localhost:9099");
      connectStorageEmulator(storage, "localhost", 9199);
    } catch (err) {
      console.warn("Error connecting to auth emulator, using staging project");
      console.warn(err);
    }
  }

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      let token = "";
      try {
        token = await getIdToken(getAuth().currentUser);
      } catch (error) {
        token = await getIdToken(getAuth().currentUser, true);
      }
      window.localStorage.setItem("oauthToken", token);
    } else {
      window.localStorage.removeItem("oauthToken");

      const route = useRoute();

      if (route.name !== "logout") {
        navigateTo("/logout");
        return;
      }
    }

    // atualiza regras do casl
    if (!loading) {
      await updateAbility(user);
    }
  });

  // aguarda de forma assíncrona o primeiro onAuthStateChanged
  await new Promise((resolve, reject) => {
    try {
      onAuthStateChanged(auth, resolve);
    } catch (error) {
      reject(error);
    }
  });

  // força update das permissões
  await updateAbility(getAuth().currentUser);

  return {
    provide: {
      auth,
      storage,
      analytics,
    },
  };
});
