import posthog from "posthog-js";

export default defineNuxtPlugin(() => {
  const { posthogKey } = useRuntimeConfig();

  if (posthogKey != null && posthogKey !== "") {
    posthog.init(posthogKey, {
      api_host: "https://app.posthog.com",
      autocapture: false,
      capture_pageview: true,
      disable_session_recording: true,
      debug: process.env.NODE_ENV === "dev",
    });
  }
});
