export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},{"charset":"utf-8"},{"name":"lang","content":"pt-br"},{"name":"description","content":"Cadastro Febrafar"}],"link":[],"style":[],"script":[{"type":"text/javascript","innerHTML":"\n            window.AppcuesSettings = {\n              enableURLDetection: true\n            };"},{"src":"//fast.appcues.com/141539.js"}],"noscript":[{"children":"Para o Cadastro Febrafar funcionar corretamente é necessário habilitar JavaScript"}],"title":"Cadastro Febrafar","charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"