import { createMongoAbility } from "@casl/ability";

import HttpUsuario from "@/http/usuario";
import {
  permissoesEnum,
  entidadeTipoEnum,
  mapaPermissaoEntidade,
  mapaPermissaoUsuario,
  produtoEnum,
} from "@/types/enums";
import { UserInformationResponse } from "@/types/usuario/requests";

import useUsuarioStorage from "@/store/usuario";
import useAuth from "@/store/auth";
import posthog from "posthog-js";

const ability = createMongoAbility();

const loading = ref(false);

// regra inicial
ability.can("visualizar", "login");

const getTipoEntidade = (acesso) => {
  if (acesso?.colaborador) {
    return entidadeTipoEnum.COLABORADOR;
  }

  if (acesso?.redeId != null) {
    return entidadeTipoEnum.REDE;
  } else if (acesso?.lojaId != null) {
    return entidadeTipoEnum.LOJA;
  } else if (acesso?.socioHonorarioId != null) {
    return entidadeTipoEnum.SOCIO_HONORARIO;
  }

  return "all";
};

const getEntidadeId = (acesso) => {
  if (getTipoEntidade(acesso) === entidadeTipoEnum.REDE) {
    return acesso.redeId;
  } else if (getTipoEntidade(acesso) === entidadeTipoEnum.LOJA) {
    return acesso.lojaId;
  } else if (getTipoEntidade(acesso) === entidadeTipoEnum.SOCIO_HONORARIO) {
    return acesso.socioHonorarioId;
  }

  return null;
};

/**
 * define regras de permissão do usuário
 *
 * @param ruleList lista de regras do casl
 * @param userInformation informações vindas do endpoint /usuarios/informacoes
 */
const fillRuleList = (
  ruleList = [],
  userInformation: UserInformationResponse = null
): void => {
  if (userInformation == null) {
    return;
  }

  // início: mapeia acessos de perfil
  if (userInformation.perfil?.socioHonorario) {
    ruleList.push({
      action: permissoesEnum.VER_ROTA,
      subject: entidadeTipoEnum.SOCIO_HONORARIO,
    });
  }

  if (userInformation.perfil?.rede) {
    ruleList.push({
      action: permissoesEnum.VER_ROTA,
      subject: entidadeTipoEnum.REDE,
    });

    ruleList.push({
      action: permissoesEnum.VER_ROTA,
      subject: entidadeTipoEnum.LOJA,
    });
  } else if (userInformation.perfil?.loja) {
    ruleList.push({
      action: permissoesEnum.VER_ROTA,
      subject: entidadeTipoEnum.LOJA,
    });
  }

  if (userInformation.perfil?.colaborador) {
    ruleList.push({
      action: permissoesEnum.VER_ROTA,
      subject: "all", // acesso geral
    });
  }

  // ordena arrays
  userInformation.vinculos?.sociosHonorarios?.sort((a, b) => a - b);
  userInformation.vinculos?.lojas?.sort((a, b) => a.loja - b.loja);
  userInformation.vinculos?.redes?.sort((a, b) => a - b);

  const priorityMap = {
    all: 0,
    [entidadeTipoEnum.COLABORADOR]: 1,
    [entidadeTipoEnum.REDE]: 2,
    [entidadeTipoEnum.LOJA]: 3,
    [entidadeTipoEnum.SOCIO_HONORARIO]: 4,
  };

  // assim os primeiros ranks são setados
  userInformation.acessos.sort(
    (a, b) => priorityMap[getTipoEntidade(a)] - priorityMap[getTipoEntidade(b)]
  );

  // fim: mapeia acessos de perfil

  // início: mapeia acessos à recursos

  userInformation.acessos.map((acesso) => {
    // busca o grupo de acesso correspondente
    const grupoAcessoFound = userInformation.gruposAcesso.find(
      ({ id }) => acesso.grupoAcesso === id
    );

    // se não encontrou, para execução
    if (grupoAcessoFound == null) {
      return;
    }

    // para todos os grupos de acesso que encontrou,
    // mapeia com as condições
    grupoAcessoFound.listaPermissoes.map((permissao: any) => {
      let addNewPermission = true;

      const newPermissionRule = {
        action: permissao,
      } as any;

      // por padrão, é genérico
      newPermissionRule.subject = "all";

      // se a permissão é relacionada a rede
      if (mapaPermissaoEntidade[entidadeTipoEnum.REDE].includes(permissao)) {
        // se a permissão for de rede
        newPermissionRule.subject = entidadeTipoEnum.REDE;

        if (
          getTipoEntidade(acesso) === entidadeTipoEnum.REDE &&
          !userInformation.perfil?.colaborador
        ) {
          newPermissionRule.conditions = {
            id: {
              $in: [getEntidadeId(acesso)],
            },
          };

          // se já existe uma permissão igual, com o array de IDs da entidade,
          // só atualiza esse array adicionando o id da entidade
          // e não adiciona uma nova permissão
          const ruleIndexFound = ruleList.findIndex(
            ({ action, subject, conditions }) =>
              action === permissao &&
              subject === entidadeTipoEnum.REDE &&
              conditions != null &&
              conditions.id?.$in != null
          );

          if (ruleIndexFound > -1) {
            addNewPermission = false;
            ruleList[ruleIndexFound].conditions.id.$in.push(
              getEntidadeId(acesso)
            );
          }
        }
      } else if (
        mapaPermissaoEntidade[entidadeTipoEnum.LOJA].includes(permissao)
      ) {
        // se a permissão for de loja
        newPermissionRule.subject = entidadeTipoEnum.LOJA;

        // a permissão é de loja mas o permissionamento de
        // entidade é a rede desta loja
        if (
          getTipoEntidade(acesso) === entidadeTipoEnum.REDE &&
          !userInformation.perfil?.colaborador
        ) {
          // adiciona regra sem condições

          if (
            ruleList.findIndex(
              ({ action, subject, conditions }) =>
                action === permissao &&
                subject === entidadeTipoEnum.LOJA &&
                conditions == null
            ) === -1
          ) {
            ruleList.push({
              action: permissao,
              subject: entidadeTipoEnum.LOJA,
            });
          }

          // e adiciona a mesma regra mas com a seguinte condição:
          // não pode editar lojas com id de rede diferente
          // da fornecida (se fornecer). se não fornecer redeId
          // então ignora
          newPermissionRule.conditions = {
            redeId: {
              $exists: true,
              $nin: [getEntidadeId(acesso)],
            },
          };
          // passando inverted true
          newPermissionRule.inverted = true;

          // se já existe uma permissão igual, com o array de IDs da entidade,
          // só atualiza esse array adicionando o id da entidade
          // e não adiciona uma nova permissão
          const ruleIndexFound = ruleList.findIndex(
            ({ action, subject, conditions, inverted }) =>
              action === permissao &&
              subject === entidadeTipoEnum.LOJA &&
              conditions != null &&
              conditions.redeId?.$nin != null &&
              inverted
          );

          if (ruleIndexFound > -1) {
            addNewPermission = false;
            ruleList[ruleIndexFound].conditions.redeId.$nin.push(
              getEntidadeId(acesso)
            );
          }
        } else if (
          getTipoEntidade(acesso) === entidadeTipoEnum.LOJA &&
          !userInformation.perfil?.colaborador
        ) {
          // cria nova permissão
          newPermissionRule.conditions = {
            id: {
              $in: [getEntidadeId(acesso)],
            },
          };

          // se já existe uma permissão igual, com o array de IDs da entidade,
          // só atualiza esse array adicionando o id da entidade
          // e não adiciona uma nova permissão
          const ruleIndexFound = ruleList.findIndex(
            ({ action, subject, conditions }) =>
              action === permissao &&
              subject === entidadeTipoEnum.LOJA &&
              conditions != null &&
              conditions.id != null &&
              conditions.id?.$in != null
          );

          if (ruleIndexFound > -1) {
            addNewPermission = false;
            ruleList[ruleIndexFound].conditions.id.$in.push(
              getEntidadeId(acesso)
            );
          }
        }
      } else if (
        mapaPermissaoEntidade[entidadeTipoEnum.SOCIO_HONORARIO].includes(
          permissao
        )
      ) {
        // se a permissão for de SH
        newPermissionRule.subject = entidadeTipoEnum.SOCIO_HONORARIO;

        if (
          getTipoEntidade(acesso) === entidadeTipoEnum.SOCIO_HONORARIO &&
          !userInformation.perfil?.colaborador
        ) {
          newPermissionRule.conditions = {
            id: {
              $in: [getEntidadeId(acesso)],
            },
          };

          // se já existe uma permissão igual, com o array de IDs da entidade,
          // só atualiza esse array adicionando o id da entidade
          // e não adiciona uma nova permissão
          const ruleIndexFound = ruleList.findIndex(
            ({ action, subject, conditions }) =>
              action === permissao &&
              subject === entidadeTipoEnum.SOCIO_HONORARIO &&
              conditions != null &&
              conditions.id != null &&
              conditions.id?.$in != null
          );

          if (ruleIndexFound > -1) {
            addNewPermission = false;
            ruleList[ruleIndexFound].conditions.id.$in.push(
              getEntidadeId(acesso)
            );
          }
        }
      } else if (
        mapaPermissaoUsuario.includes(permissao) &&
        !userInformation.perfil?.colaborador
      ) {
        // adiciona regra sem condições
        if (
          ruleList.findIndex(
            ({ action, subject, conditions }) =>
              action === permissao &&
              subject === getTipoEntidade(acesso) &&
              conditions == null
          ) === -1
        ) {
          ruleList.push({
            action: permissao,
            subject: getTipoEntidade(acesso),
          });
        }

        // e adiciona a mesma regra mas com a seguinte condição:
        // não pode editar usuarios com entidadeId diferente
        // da fornecida (se fornecer). se não fornecer entidadeId
        // então ignora
        newPermissionRule.subject = getTipoEntidade(acesso);
        newPermissionRule.conditions = {
          id: {
            $exists: true,
            $nin: [getEntidadeId(acesso)],
          },
        };

        // passando inverted true
        newPermissionRule.inverted = true;

        // se já existe uma permissão igual, com o array de IDs da entidade,
        // só atualiza esse array adicionando o id da entidade
        // e não adiciona uma nova permissão
        const ruleIndexFound = ruleList.findIndex(
          ({ action, subject, conditions, inverted }) =>
            action === permissao &&
            subject === getTipoEntidade(acesso) &&
            conditions != null &&
            conditions.id != null &&
            conditions.id?.$nin != null &&
            inverted
        );

        if (ruleIndexFound > -1) {
          addNewPermission = false;
          ruleList[ruleIndexFound].conditions.id.$nin.push(
            getEntidadeId(acesso)
          );
        }
      }

      // TODO: melhorar lista de permissões, tornar
      // mais fácil de ler o código
      if (addNewPermission) {
        if (
          ruleList.findIndex(
            ({ action, subject, conditions }) =>
              action === newPermissionRule.action &&
              subject === newPermissionRule.subject &&
              conditions == newPermissionRule.conditions &&
              conditions == null
          ) === -1
        )
          ruleList.push(newPermissionRule);
      }
    });
  });
  // fim: mapeia acessos à recursos

  // deixa salvo essas informações do usuário pra gente consultar o perfil
  // do usuário
  useUsuarioStorage.loggedUserInfo = userInformation;
};

/**
 * chame esta função para atualizar regras
 * TODO: definir que parâmetro estamos aguardando aqui
 *
 * por enquanto está enviando o user do firebase
 * talvez aqui a gente consultaria uma API do usuário
 * logado e retornaria um array de permissões formatadas
 *
 * @param user usuário no firebase, para identificar se está logado
 * ou deslogado
 */
export const updateAbility = async (user?: any) => {
  const route = useRoute();

  if (["problema", "logout"].includes(String(route.name))) {
    return;
  }

  const { $setGtagInformation, $sentry } = useNuxtApp();

  const loggedIn = user != null;
  let errorInformation: any = null;

  let userInformation: UserInformationResponse = null;

  loading.value = true;
  if (loggedIn) {
    try {
      userInformation = await HttpUsuario.getUserInformation();

      if (
        userInformation != null &&
        userInformation.gruposAcesso.length === 0
      ) {
        userInformation = null;
      }
    } catch (error) {
      console.error(error);
      errorInformation = error;
      useUsuarioStorage.loggedUserInfo = null;
      userInformation = null;
    }
  } else {
    useUsuarioStorage.loggedUserInfo = null;
    userInformation = null;
  }
  loading.value = false;

  // se tá logado mas não conseguiu pegar informação do usuário
  // desloga o usuário
  if (loggedIn && userInformation == null) {
    // erro de timeout, somente redireciona
    if (errorInformation != null && errorInformation.code === "ECONNABORTED") {
      $sentry.captureException(
        new Error(
          "Ocorreu um erro de timeout ao buscar informações do usuário"
        ),
        {
          extra: errorInformation,
        }
      );

      localStorage.setItem(
        "retryUrl",
        ["problema", "logout"].includes(String(route.name))
          ? `${window.location.protocol}//${window.location.host}`
          : `${window.location.protocol}//${window.location.host}${route.fullPath}`
      );
      const timeoutUrl = `${window.location.protocol}//${window.location.host}/problema`;
      window.location.replace(timeoutUrl);

      return;
    }

    // desloga do firebase
    await useAuth.logout();

    const logoutUrl = `${window.location.protocol}//${window.location.host}/logout`;

    // redirect (em teoria n entra em loop infinito pois
    // deslogou do firebase antes então loggedIn será false)
    window.location.replace(logoutUrl);
    return;
  }

  $setGtagInformation(userInformation);

  // para ler mais: https://casl.js.org/v6/en/guide/define-rules
  let updatedRuleList = [];

  // usuário logado
  if (loggedIn) {
    // identifica usuario logado pelo id unico no posthog
    posthog.identify(String(userInformation.id), {
      email: userInformation.email,
    });

    fillRuleList(updatedRuleList, userInformation);

    updatedRuleList.push({
      action: "visualizar",
      subject: "login",
      // inverte lógica, usuário logado NÃO pode ver login
      inverted: true,
    });
  } else {
    // regras de usuário deslogado
    updatedRuleList = [
      {
        action: "visualizar",
        subject: "login",
      },
    ];
  }

  ability.update(updatedRuleList);
};

export { ability, loading };
