import VueGtag, { set } from "vue-gtag";
import { config, event } from "vue-gtag";
import useUsuarioStorage from "@/store/usuario";
import { UserInformationResponse } from "@/types/usuario/requests";
import { entidadeTipoEnum, produtoEnum } from "@/types/enums";

export default defineNuxtPlugin(({ vueApp, $router }) => {
  const { environment, gtagId } = useRuntimeConfig();

  const getRedeList = (userInformation: UserInformationResponse) => {
    if (userInformation == null) {
      return [];
    }

    if (
      (userInformation.perfil?.colaborador || userInformation.perfil?.rede) &&
      userInformation.vinculos?.redes.length > 0
    ) {
      return userInformation.vinculos?.redes;
    }

    if (userInformation.vinculos?.lojas?.length > 0) {
      return userInformation.vinculos?.lojas
        .map(({ rede }) => rede)
        .sort((a, b) => a - b);
    }

    return [];
  };

  vueApp.use(
    VueGtag,
    {
      config: {
        id: gtagId,
        params: {
          send_page_view: true,
        },
      },
      onAfterTrack() {
        if (useUsuarioStorage.loggedUserInfo == null) {
          return;
        }

        // não dá page_view do primeiro sh
        for (
          let i = 1;
          i < useUsuarioStorage.loggedUserInfo.vinculos.sociosHonorarios.length;
          i++
        ) {
          // testando se com timeout vai a informação
          // com os demais ids
          setTimeout(() => {
            event("page_view", {
              user_properties: {
                sh_id:
                  useUsuarioStorage.loggedUserInfo.vinculos.sociosHonorarios[i],
              },
            });
          }, 100);
        }

        // não dá page_view da primeira rede
        for (
          let i = 1;
          i < getRedeList(useUsuarioStorage.loggedUserInfo).length;
          i++
        ) {
          // testando se com timeout vai a informação
          // com os demais ids
          setTimeout(() => {
            event("page_view", {
              user_properties: {
                rede_id: getRedeList(useUsuarioStorage.loggedUserInfo)[i],
              },
            });
          }, 100);
        }
      },
    },
    $router
  );

  // só vai enviar dados de navegação em produção
  if (environment !== "production") {
    config({
      debug_mode: true,
    });
  }

  /**
   * seta informações no gtag do usuário
   *
   * @param userInformation informações vindas do endpoint /usuarios/informacoes
   */
  const setGtagInformation = (
    userInformation: UserInformationResponse = null
  ) => {
    if (userInformation === null) {
      set({
        user_id: null,
        user_properties: {
          teste_usuario_id: null,
        },
      });

      return;
    }

    set({
      user_id: userInformation.id,
      user_properties: {
        teste_usuario_id: userInformation.id,
        produto: produtoEnum.CADASTRO,
      },
    });

    // início: mapeia perfil
    if (userInformation.perfil?.colaborador) {
      set({
        user_properties: {
          perfil: entidadeTipoEnum.COLABORADOR,
        },
      });
    } else if (userInformation.perfil?.rede) {
      set({
        user_properties: {
          perfil: entidadeTipoEnum.REDE,
        },
      });
    } else if (userInformation.perfil?.loja) {
      set({
        user_properties: {
          perfil: entidadeTipoEnum.LOJA,
        },
      });
    } else if (userInformation.perfil?.socioHonorario) {
      set({
        user_properties: {
          perfil: entidadeTipoEnum.SOCIO_HONORARIO,
        },
      });
    }

    // ordena arrays
    userInformation.vinculos.sociosHonorarios.sort((a, b) => a - b);
    userInformation.vinculos.redes.sort((a, b) => a - b);

    // seta o id mais antigo
    set({
      user_properties: {
        sh_id:
          userInformation.vinculos.sociosHonorarios.length > 0
            ? userInformation.vinculos.sociosHonorarios[0]
            : null,
        rede_id:
          getRedeList(userInformation).length > 0
            ? getRedeList(userInformation)[0]
            : null,
      },
    });
  };

  return {
    provide: {
      setGtagInformation,
    },
  };
});
