export default defineNuxtPlugin(() => {
  // TODO: Rever quando o modulo do nuxt/pwa estiver compatível com nuxt3
  if (
    "serviceWorker" in navigator &&
    !window.location.host.includes("localhost")
  ) {
    window.addEventListener("load", () => {
      if (!("serviceWorker" in navigator)) {
        throw new Error("serviceWorker is not supported in current browser!");
      }
      navigator.serviceWorker.register("/sw.js");
    });
  }
});
