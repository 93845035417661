type ToastType = "success" | "warn" | "error" | "info" | string;

interface Button {
  text: string;
  onClick?: (toast: Toast) => void;
}

export interface Options {
  type?: ToastType;
  time?: number;
  buttons?: Button[];
  hideClose?: boolean;
  // função executada quando o toast era fechado sozinho
  onClose?: Function;
  doNotAllowCloseFromPlugin?: boolean;
}

interface Toast {
  message: string;
  type: ToastType;
  time: number;
  buttons?: Button[];
  hideClose?: boolean;
  // função executada quando o toast era fechado sozinho
  onClose?: Function;
  doNotAllowCloseFromPlugin?: boolean;
}

const DEFAULT_TIMEOUT = 12 * 1000;

export const useToast = () => {
  return {
    toastTimeout: useState("toastTimeout", () => null),
    toast: useState("toast", () => null),
    close: () => {
      const { toast } = useToast();
      toast.value = null;
    },
    show: (
      message: string,
      {
        time = DEFAULT_TIMEOUT,
        type = "info",
        buttons,
        hideClose,
        onClose = null,
        doNotAllowCloseFromPlugin = false,
      }: Options
    ) => {
      const { toastTimeout, toast, close } = useToast();

      clearTimeout(toastTimeout.value);

      toast.value = {
        message,
        time,
        type,
        buttons,
        hideClose,
        onClose,
        doNotAllowCloseFromPlugin,
      };

      // caso passe time = 0, o toast ficará visível até que o usuário
      // clique em fechar ou clique em um dos botões
      if (time > 0) {
        toastTimeout.value = setTimeout(async () => {
          // caso tenha uma função a ser executada,
          // chama primeiro antes de fechar o toast

          // o onClose será chamado apenas nos seguintes cenários:
          // 1 - acabou o timeout
          // 2 - clicou no "x" do toast
          if (toast.value != null && toast.value.onClose != null) {
            await toast.value.onClose();
          }

          close();
        }, time);
      }
    },
  };
};
