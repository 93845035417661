import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import auth_45global from "/opt/atlassian/pipelines/agent/build/middleware/auth.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global
]
export const namedMiddleware = {
  "grupo-acesso": () => import("/opt/atlassian/pipelines/agent/build/middleware/grupo-acesso.ts"),
  home: () => import("/opt/atlassian/pipelines/agent/build/middleware/home.ts"),
  loja: () => import("/opt/atlassian/pipelines/agent/build/middleware/loja.ts"),
  performance: () => import("/opt/atlassian/pipelines/agent/build/middleware/performance.ts"),
  rede: () => import("/opt/atlassian/pipelines/agent/build/middleware/rede.ts"),
  "socio-honorario": () => import("/opt/atlassian/pipelines/agent/build/middleware/socio-honorario.ts"),
  usuario: () => import("/opt/atlassian/pipelines/agent/build/middleware/usuario.ts")
}