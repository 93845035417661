import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as logoutIRtMjIHqbmMeta } from "/opt/atlassian/pipelines/agent/build/pages/logout.vue?macro=true";
import { default as cadastrarN08sZMzMXKMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/cadastrar.vue?macro=true";
import { default as _91usuario_93hnAf7RJrPeMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as indexXiMpgqwjAbMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/editar/[id]/index.vue?macro=true";
import { default as index7R4wGVCRtoMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/index.vue?macro=true";
import { default as mapaPLwsLIZhKpMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/mapa.vue?macro=true";
import { default as _91id_93rMZ8LDDg6YMeta } from "/opt/atlassian/pipelines/agent/build/pages/lojas/visualizar/[id].vue?macro=true";
import { default as indexpeWqLkKhyBMeta } from "/opt/atlassian/pipelines/agent/build/pages/performance/index.vue?macro=true";
import { default as cadastrarU0xJ9TDdLPMeta } from "/opt/atlassian/pipelines/agent/build/pages/permissoes/cadastrar.vue?macro=true";
import { default as _91id_93YXuZZ630gUMeta } from "/opt/atlassian/pipelines/agent/build/pages/permissoes/editar/[id].vue?macro=true";
import { default as indexifDq3GRS8mMeta } from "/opt/atlassian/pipelines/agent/build/pages/permissoes/index.vue?macro=true";
import { default as _91id_93dApCwNs4YPMeta } from "/opt/atlassian/pipelines/agent/build/pages/permissoes/visualizar/[id].vue?macro=true";
import { default as problema0KmyAhkcWBMeta } from "/opt/atlassian/pipelines/agent/build/pages/problema.vue?macro=true";
import { default as cadastrarbGeJaYkfNkMeta } from "/opt/atlassian/pipelines/agent/build/pages/redes/cadastrar.vue?macro=true";
import { default as _91usuario_93dXGx2Z0CpDMeta } from "/opt/atlassian/pipelines/agent/build/pages/redes/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as index28Kq5F2ptUMeta } from "/opt/atlassian/pipelines/agent/build/pages/redes/editar/[id]/index.vue?macro=true";
import { default as indexeJ1LLGsF3hMeta } from "/opt/atlassian/pipelines/agent/build/pages/redes/index.vue?macro=true";
import { default as _91id_93nFxLsG56Q3Meta } from "/opt/atlassian/pipelines/agent/build/pages/redes/visualizar/[id].vue?macro=true";
import { default as indexFpd8XNOJA3Meta } from "/opt/atlassian/pipelines/agent/build/pages/sh/cadastrar/index.vue?macro=true";
import { default as _91usuario_93LmVVHVEXXmMeta } from "/opt/atlassian/pipelines/agent/build/pages/sh/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as index19m9FYnsJ0Meta } from "/opt/atlassian/pipelines/agent/build/pages/sh/editar/[id]/index.vue?macro=true";
import { default as indexcN96eqxwwEMeta } from "/opt/atlassian/pipelines/agent/build/pages/sh/index.vue?macro=true";
import { default as _91id_93jTOAZAk464Meta } from "/opt/atlassian/pipelines/agent/build/pages/sh/visualizar/[id].vue?macro=true";
import { default as _91token_93lQun16pCybMeta } from "/opt/atlassian/pipelines/agent/build/pages/sso/[token].vue?macro=true";
import { default as cadastrarY2lpnJOlLaMeta } from "/opt/atlassian/pipelines/agent/build/pages/usuarios/cadastrar.vue?macro=true";
import { default as _91id_93dl4a3kSL0oMeta } from "/opt/atlassian/pipelines/agent/build/pages/usuarios/editar/[id].vue?macro=true";
import { default as indexHvH9pPY5ewMeta } from "/opt/atlassian/pipelines/agent/build/pages/usuarios/importar/index.vue?macro=true";
import { default as indexwtJ6fQRT9HMeta } from "/opt/atlassian/pipelines/agent/build/pages/usuarios/index.vue?macro=true";
import { default as _91id_93Bvr1iPLCczMeta } from "/opt/atlassian/pipelines/agent/build/pages/usuarios/visualizar/[id].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/:slug(.*)*",
    children: [],
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    children: [],
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutIRtMjIHqbmMeta?.name ?? "logout",
    path: logoutIRtMjIHqbmMeta?.path ?? "/logout",
    children: [],
    meta: logoutIRtMjIHqbmMeta || {},
    alias: logoutIRtMjIHqbmMeta?.alias || [],
    redirect: logoutIRtMjIHqbmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: cadastrarN08sZMzMXKMeta?.name ?? "lojas-cadastrar",
    path: cadastrarN08sZMzMXKMeta?.path ?? "/lojas/cadastrar",
    children: [],
    meta: cadastrarN08sZMzMXKMeta || {},
    alias: cadastrarN08sZMzMXKMeta?.alias || [],
    redirect: cadastrarN08sZMzMXKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93hnAf7RJrPeMeta?.name ?? "lojas-editar-id-convidar-usuario",
    path: _91usuario_93hnAf7RJrPeMeta?.path ?? "/lojas/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93hnAf7RJrPeMeta || {},
    alias: _91usuario_93hnAf7RJrPeMeta?.alias || [],
    redirect: _91usuario_93hnAf7RJrPeMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: indexXiMpgqwjAbMeta?.name ?? "lojas-editar-id",
    path: indexXiMpgqwjAbMeta?.path ?? "/lojas/editar/:id",
    children: [],
    meta: indexXiMpgqwjAbMeta || {},
    alias: indexXiMpgqwjAbMeta?.alias || [],
    redirect: indexXiMpgqwjAbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7R4wGVCRtoMeta?.name ?? "lojas",
    path: index7R4wGVCRtoMeta?.path ?? "/lojas",
    children: [],
    meta: index7R4wGVCRtoMeta || {},
    alias: index7R4wGVCRtoMeta?.alias || [],
    redirect: index7R4wGVCRtoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/index.vue").then(m => m.default || m)
  },
  {
    name: mapaPLwsLIZhKpMeta?.name ?? "lojas-mapa",
    path: mapaPLwsLIZhKpMeta?.path ?? "/lojas/mapa",
    children: [],
    meta: mapaPLwsLIZhKpMeta || {},
    alias: mapaPLwsLIZhKpMeta?.alias || [],
    redirect: mapaPLwsLIZhKpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/mapa.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rMZ8LDDg6YMeta?.name ?? "lojas-visualizar-id",
    path: _91id_93rMZ8LDDg6YMeta?.path ?? "/lojas/visualizar/:id",
    children: [],
    meta: _91id_93rMZ8LDDg6YMeta || {},
    alias: _91id_93rMZ8LDDg6YMeta?.alias || [],
    redirect: _91id_93rMZ8LDDg6YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lojas/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpeWqLkKhyBMeta?.name ?? "performance",
    path: indexpeWqLkKhyBMeta?.path ?? "/performance",
    children: [],
    meta: indexpeWqLkKhyBMeta || {},
    alias: indexpeWqLkKhyBMeta?.alias || [],
    redirect: indexpeWqLkKhyBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/performance/index.vue").then(m => m.default || m)
  },
  {
    name: cadastrarU0xJ9TDdLPMeta?.name ?? "permissoes-cadastrar",
    path: cadastrarU0xJ9TDdLPMeta?.path ?? "/permissoes/cadastrar",
    children: [],
    meta: cadastrarU0xJ9TDdLPMeta || {},
    alias: cadastrarU0xJ9TDdLPMeta?.alias || [],
    redirect: cadastrarU0xJ9TDdLPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/permissoes/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YXuZZ630gUMeta?.name ?? "permissoes-editar-id",
    path: _91id_93YXuZZ630gUMeta?.path ?? "/permissoes/editar/:id",
    children: [],
    meta: _91id_93YXuZZ630gUMeta || {},
    alias: _91id_93YXuZZ630gUMeta?.alias || [],
    redirect: _91id_93YXuZZ630gUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/permissoes/editar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexifDq3GRS8mMeta?.name ?? "permissoes",
    path: indexifDq3GRS8mMeta?.path ?? "/permissoes",
    children: [],
    meta: indexifDq3GRS8mMeta || {},
    alias: indexifDq3GRS8mMeta?.alias || [],
    redirect: indexifDq3GRS8mMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/permissoes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dApCwNs4YPMeta?.name ?? "permissoes-visualizar-id",
    path: _91id_93dApCwNs4YPMeta?.path ?? "/permissoes/visualizar/:id",
    children: [],
    meta: _91id_93dApCwNs4YPMeta || {},
    alias: _91id_93dApCwNs4YPMeta?.alias || [],
    redirect: _91id_93dApCwNs4YPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/permissoes/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: problema0KmyAhkcWBMeta?.name ?? "problema",
    path: problema0KmyAhkcWBMeta?.path ?? "/problema",
    children: [],
    meta: problema0KmyAhkcWBMeta || {},
    alias: problema0KmyAhkcWBMeta?.alias || [],
    redirect: problema0KmyAhkcWBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/problema.vue").then(m => m.default || m)
  },
  {
    name: cadastrarbGeJaYkfNkMeta?.name ?? "redes-cadastrar",
    path: cadastrarbGeJaYkfNkMeta?.path ?? "/redes/cadastrar",
    children: [],
    meta: cadastrarbGeJaYkfNkMeta || {},
    alias: cadastrarbGeJaYkfNkMeta?.alias || [],
    redirect: cadastrarbGeJaYkfNkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/redes/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93dXGx2Z0CpDMeta?.name ?? "redes-editar-id-convidar-usuario",
    path: _91usuario_93dXGx2Z0CpDMeta?.path ?? "/redes/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93dXGx2Z0CpDMeta || {},
    alias: _91usuario_93dXGx2Z0CpDMeta?.alias || [],
    redirect: _91usuario_93dXGx2Z0CpDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/redes/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: index28Kq5F2ptUMeta?.name ?? "redes-editar-id",
    path: index28Kq5F2ptUMeta?.path ?? "/redes/editar/:id",
    children: [],
    meta: index28Kq5F2ptUMeta || {},
    alias: index28Kq5F2ptUMeta?.alias || [],
    redirect: index28Kq5F2ptUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/redes/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeJ1LLGsF3hMeta?.name ?? "redes",
    path: indexeJ1LLGsF3hMeta?.path ?? "/redes",
    children: [],
    meta: indexeJ1LLGsF3hMeta || {},
    alias: indexeJ1LLGsF3hMeta?.alias || [],
    redirect: indexeJ1LLGsF3hMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/redes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nFxLsG56Q3Meta?.name ?? "redes-visualizar-id",
    path: _91id_93nFxLsG56Q3Meta?.path ?? "/redes/visualizar/:id",
    children: [],
    meta: _91id_93nFxLsG56Q3Meta || {},
    alias: _91id_93nFxLsG56Q3Meta?.alias || [],
    redirect: _91id_93nFxLsG56Q3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/redes/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFpd8XNOJA3Meta?.name ?? "sh-cadastrar",
    path: indexFpd8XNOJA3Meta?.path ?? "/sh/cadastrar",
    children: [],
    meta: indexFpd8XNOJA3Meta || {},
    alias: indexFpd8XNOJA3Meta?.alias || [],
    redirect: indexFpd8XNOJA3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sh/cadastrar/index.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93LmVVHVEXXmMeta?.name ?? "sh-editar-id-convidar-usuario",
    path: _91usuario_93LmVVHVEXXmMeta?.path ?? "/sh/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93LmVVHVEXXmMeta || {},
    alias: _91usuario_93LmVVHVEXXmMeta?.alias || [],
    redirect: _91usuario_93LmVVHVEXXmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sh/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: index19m9FYnsJ0Meta?.name ?? "sh-editar-id",
    path: index19m9FYnsJ0Meta?.path ?? "/sh/editar/:id",
    children: [],
    meta: index19m9FYnsJ0Meta || {},
    alias: index19m9FYnsJ0Meta?.alias || [],
    redirect: index19m9FYnsJ0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sh/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcN96eqxwwEMeta?.name ?? "sh",
    path: indexcN96eqxwwEMeta?.path ?? "/sh",
    children: [],
    meta: indexcN96eqxwwEMeta || {},
    alias: indexcN96eqxwwEMeta?.alias || [],
    redirect: indexcN96eqxwwEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sh/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jTOAZAk464Meta?.name ?? "sh-visualizar-id",
    path: _91id_93jTOAZAk464Meta?.path ?? "/sh/visualizar/:id",
    children: [],
    meta: _91id_93jTOAZAk464Meta || {},
    alias: _91id_93jTOAZAk464Meta?.alias || [],
    redirect: _91id_93jTOAZAk464Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sh/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93lQun16pCybMeta?.name ?? "sso-token",
    path: _91token_93lQun16pCybMeta?.path ?? "/sso/:token",
    children: [],
    meta: _91token_93lQun16pCybMeta || {},
    alias: _91token_93lQun16pCybMeta?.alias || [],
    redirect: _91token_93lQun16pCybMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sso/[token].vue").then(m => m.default || m)
  },
  {
    name: cadastrarY2lpnJOlLaMeta?.name ?? "usuarios-cadastrar",
    path: cadastrarY2lpnJOlLaMeta?.path ?? "/usuarios/cadastrar",
    children: [],
    meta: cadastrarY2lpnJOlLaMeta || {},
    alias: cadastrarY2lpnJOlLaMeta?.alias || [],
    redirect: cadastrarY2lpnJOlLaMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/usuarios/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dl4a3kSL0oMeta?.name ?? "usuarios-editar-id",
    path: _91id_93dl4a3kSL0oMeta?.path ?? "/usuarios/editar/:id",
    children: [],
    meta: _91id_93dl4a3kSL0oMeta || {},
    alias: _91id_93dl4a3kSL0oMeta?.alias || [],
    redirect: _91id_93dl4a3kSL0oMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/usuarios/editar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHvH9pPY5ewMeta?.name ?? "usuarios-importar",
    path: indexHvH9pPY5ewMeta?.path ?? "/usuarios/importar",
    children: [],
    meta: indexHvH9pPY5ewMeta || {},
    alias: indexHvH9pPY5ewMeta?.alias || [],
    redirect: indexHvH9pPY5ewMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/usuarios/importar/index.vue").then(m => m.default || m)
  },
  {
    name: indexwtJ6fQRT9HMeta?.name ?? "usuarios",
    path: indexwtJ6fQRT9HMeta?.path ?? "/usuarios",
    children: [],
    meta: indexwtJ6fQRT9HMeta || {},
    alias: indexwtJ6fQRT9HMeta?.alias || [],
    redirect: indexwtJ6fQRT9HMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Bvr1iPLCczMeta?.name ?? "usuarios-visualizar-id",
    path: _91id_93Bvr1iPLCczMeta?.path ?? "/usuarios/visualizar/:id",
    children: [],
    meta: _91id_93Bvr1iPLCczMeta || {},
    alias: _91id_93Bvr1iPLCczMeta?.alias || [],
    redirect: _91id_93Bvr1iPLCczMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/usuarios/visualizar/[id].vue").then(m => m.default || m)
  }
]