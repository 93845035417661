import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { ErrorResponse } from "@/types/requests";

// https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543
export default defineNuxtPlugin((nuxtApp) => {
  const { environment, sentryUrl, sentryTracingOrigins, clientVersion } =
    useRuntimeConfig();
  const { vueApp, $router, $notify } = nuxtApp;

  Sentry.init({
    environment,
    app: vueApp,
    dsn: sentryUrl,
    release: clientVersion,
    allowUrls: [
      /https:\/\/(www\.)?((hml|treinamento)\.)?cadastro\.febrafar\.com\.br/,
    ],
    ignoreErrors: [
      /AbortError:/i,
      /NotSupportedError:/i,
      /Failed to fetch/i,
      /An error occurred during playback/i,
      /null is not an object/i,
      /Unknown player. Probably unloaded/i,
      /Permission denied to access property/i,
      /Seu usuário não tem acesso ao produto/i,
      /Cannot create property 'status' on string/i,
      /Não foi possível consultar o usuário logado atualmente/i,
      /An error occurred during playback/i,
      /Non-Error promise rejection captured with keys/i,
      // request.ts
      /Too many retried requests/i,
    ],
    beforeSend(event, hint) {
      const error = hint.originalException || hint.syntheticException;

      let mensagemErro = null;

      if (typeof error === "string") {
        mensagemErro = error;
      } else if (error != null && typeof (error as any)?.message === "string") {
        mensagemErro = (error as any)?.message;
      }

      if (mensagemErro == null) {
        return event;
      }

      const blacklistErrosComuns = [
        "failed to fetch dynamically imported module",
        "an error occurred during playback",
        "seu usuário não tem acesso ao produto",
        "usuário não encontrado",
        "can't access dead object",
        "auth/invalid-custom-token",
        "auth/user-token-expired",
        "unknown player. probably unloaded",
        "installations/app-offline",
        "network error",
        "cannot create property 'status' on string",
        "auth/wrong-password",
        "cannot read properties of null (reading 'postmessage')",
      ];

      if (
        blacklistErrosComuns.some((erroComum) =>
          mensagemErro.toLowerCase().includes(erroComum)
        )
      ) {
        return null;
      }

      return event;
    },
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation($router),
        tracePropagationTargets: sentryTracingOrigins,
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment === "production" ? 0.5 : 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: environment === "production" ? 0.1 : 0.5,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: environment === "production" ? 0.1 : 0.5,
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    })
  );

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  const notifyError = (
    error?: { message: string; data?: ErrorResponse },
    defaultErrorMessage?: string,
    onlyNotifyOnUnknownError: boolean = false,
    doNotAllowCloseFromPlugin: boolean = false,
    onlyLogOnSentry: boolean = false
  ) => {
    console.error(error);

    if (error == null) {
      if (!onlyNotifyOnUnknownError && !onlyLogOnSentry) {
        $notify(
          defaultErrorMessage ||
            "Houve um problema desconhecido. Por favor, entre em contato com o Cuidado ao Associado.",
          {
            type: "error",
            doNotAllowCloseFromPlugin,
          }
        );
      }

      return;
    }

    // se for erro desconhecido, dá opção de reportar pro sentry
    if (error.data?.cod === "FEBRA_999") {
      if (!onlyLogOnSentry) {
        $notify(
          "Houve um problema desconhecido no servidor! O erro foi reportado com os dados de navegação.",
          {
            type: "error",
            doNotAllowCloseFromPlugin,
          }
        );
      }

      const errorMessage =
        error.data?.errorMessage ||
        error.data?.dsc ||
        error.data?.message ||
        defaultErrorMessage ||
        "Houve um problema desconhecido. Por favor, entre em contato com o Cuidado ao Associado.";

      // forçar envio de erro
      Sentry.captureException(new Error(errorMessage), {
        extra: error,
      });
    } else {
      if (!onlyNotifyOnUnknownError && !onlyLogOnSentry) {
        $notify(
          error.data?.dsc ||
            defaultErrorMessage ||
            "Houve um problema desconhecido. Por favor, entre em contato com o Cuidado ao Associado.",
          {
            type: "error",
            doNotAllowCloseFromPlugin,
          }
        );
      }
    }
  };

  return {
    provide: {
      sentry: Sentry,
      sentrySetContext: (n, context) => Sentry.setContext(n, context),
      sentrySetUser: (user) => Sentry.setUser(user),
      sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
      notifyError,
    },
  };
});
