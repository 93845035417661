import { NAME_URLS } from "@/utils/logger";
import { produtoEnum } from "@/types/enums";

let window: Window;

export default defineNuxtRouteMiddleware((to) => {
  // workaround for this problem
  // https://github.com/vitejs/vite/issues/11804
  const router = useRouter();
  router.onError((error, to) => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      window.location = to.fullPath;
    }
  });

  const { $notifyClose, $ability, $request } = useNuxtApp();
  const pageName = String(to.name);

  $notifyClose();

  const logger = NAME_URLS(to.params.id, to.params.usuario).filter((url) => {
    return url.name === to.name;
  });

  if (logger.length > 0) {
    $request({
      method: "POST",
      endpoint: "/eventos/enviar-evento",
      product: produtoEnum.CADASTRO,
      body: {
        ...logger[0],
        informacoes: logger[0].informacoes
          ? logger[0].informacoes
          : [
              {
                chave: null,
                valor: null,
              },
            ],
      },
    }).catch((error) => console.error(error));
  }

  // se for página de sso, ignora a validação abaixo
  if (pageName === "sso-token") {
    return;
  }

  // lugares onde o usuário deve estar deslogado
  if (pageName !== "logout" && $ability.can("visualizar", "login")) {
    return navigateTo("/logout");
  }
});
